import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Stack, Typography} from '@mui/material';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {ISearchFilter} from '../../../../models/ISearchFilter';
import CompanyFilterLeft from './CompanyFilterLeft';
import CompanyFilterMiddle from './CompanyFilterMiddle';
import Layout from '../../../Layout';
import CompanyPositionHeader from '../../../../components/company/CompanyPositionHeader';
import useJobPositions from '../../../../hooks/useJobPositions';
import useFilterApi from '../../../../api/useFilterApi';
import useJobPositionApi from '../../../../api/useJobPositionApi';
import CompanyFilterRight from './CompanyFilterRight';
import FilterIntroModal from "../../../../components/company/FilterIntroModal";
import PositionStepper from "../PositionStepper";

function CompanyFilter() {
    const {getPosition} = useJobPositionApi();
    const {savePositionFilter} = useJobPositions();
    const {searchCount} = useFilterApi();
    const {id} = useParams();
    const [params, setSearchParams] = useSearchParams();

    const [open, setOpen] = useState(false);
    const handleClose = () => {
        params.delete("intro");
        setSearchParams(params);
        setOpen(false);
    }

    const [searchFilter, setSearchFilter] = useState<ISearchFilter>({
        metrixRanges: [],
        jobPositionId: id ? parseInt(id) : null,
        mismatches: 0,
    });


    const [matchCount, setMatchCount] = useState<number[]>([0, 0, 0]);
    const [chartUpdateKey, setChartUpdateKey] = useState<number>(0);
    const navigate = useNavigate();

    useEffect(() => {
        loadFilterFromNewPosition();
    }, [id]);

    const loadFilterFromNewPosition = async () => {
        if (id) {
            const pos = await getPosition(parseInt(id));
            if (pos.filter) {
                const loadedFilter = JSON.parse(pos?.filter) as ISearchFilter;
                if (!loadedFilter.mismatches) {
                    loadedFilter.mismatches = 0;
                }
                await updateFilter(loadedFilter, true, false);
            }
            if (params.get("intro")) {
                //setOpen(true);
            }
        }
    };

    const updateFilter = async (newFilter: ISearchFilter, updateCharts: boolean, saveFilter: boolean = true, updateCount: boolean = true) => {
        setSearchFilter((prev) => newFilter);
        if (id) {
            if (updateCount) {
                searchCount(parseInt(id), newFilter, 0).then((counts) => {
                    if (counts !== undefined && counts !== null) {
                        setMatchCount((prev) => counts);
                    } else {
                        setMatchCount((prev) => [0, 0, 0]);
                    }
                });
            }

            if (updateCharts) {
                setChartUpdateKey((prev) => chartUpdateKey + 1);
            }

            if (saveFilter) {
                await savePositionFilter(parseInt(id), newFilter);
            }
        }
    };

    if (!searchFilter) {
        return (
            <Layout>
                <Stack spacing={1}>
                    <Typography typography="h3">Job position was not found</Typography>
                </Stack>
            </Layout>
        );
    }

    return (
        <Layout>
            <Stack spacing={1}>
                <CompanyPositionHeader showLinks/>
                <Stack direction="row" spacing={1}>
                    <CompanyFilterLeft searchFilter={searchFilter} updateFilter={updateFilter}
                                       chartUpdateKey={chartUpdateKey}/>
                    <CompanyFilterMiddle
                        searchFilter={searchFilter}
                        updateFilter={updateFilter}
                        chartUpdateKey={chartUpdateKey}
                        matchCount={matchCount}
                    />
                    <PositionStepper activeStep={0}>
                        <CompanyFilterRight searchFilter={searchFilter} updateFilter={updateFilter}
                                            matchCount={matchCount}/>
                    </PositionStepper>

                </Stack>
                <FilterIntroModal open={open} handleClose={handleClose}/>
            </Stack>
        </Layout>
    );
}

export default CompanyFilter;
