import React from 'react';
import {Button, Paper, Stack, Step, StepContent, StepLabel, Stepper} from '@mui/material';
import {useParams} from 'react-router-dom';
import {styles} from '../../../components/theme';
import {useNavigate} from "react-router";

const steps = [
    {
        label: 'Filter and select matches',
        link: '/filter',
    },
    {
        label: 'Select',
        link: '/select',
    },
    {
        label: 'Contact request',
        link: '/offer',
    },
];

function PositionStepper({children, activeStep}: { children: JSX.Element, activeStep: number }) {
    const {id} = useParams();
    const navigate = useNavigate();

    const next = () => {
        const url = `/positions/${id}` + steps[activeStep + 1].link;
        navigate(url);
    };

    const back = () => {
        const url = `/positions/${id}` + steps[activeStep - 1].link;
        navigate(url);
    };

    const onLabelClick = (step: any, index: number) => {
        if (index < activeStep) {
            navigate(`/positions/${id}` + step.link)
        }
    }

    return (
        <Stack sx={styles.rightStack} spacing={1}>
            <Paper elevation={0}
                   sx={{
                       ...styles.paper,
                       width: styles.rightStack.width
                   }}>
                <Stepper activeStep={activeStep} orientation="vertical" sx={{
                    '& .MuiStepLabel-label.Mui-active': {
                        color: '#000000',
                        fontWeight: '700',
                    },
                }}>
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel onClick={() => onLabelClick(step, index)}
                                       sx={index < activeStep ? {cursor: 'pointer'} : {}}>
                                {step.label}
                            </StepLabel>
                            <StepContent>
                                {children}
                                {index !== 0 && (
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            height: '1.875rem',
                                            color: '#FFA049',
                                            fontSize: '0.875rem',
                                            width: '4rem',
                                            marginTop: '1rem',
                                            marginRight: '0.25rem'
                                        }}
                                        onClick={() => back()}
                                    >
                                        Back
                                    </Button>
                                )}
                                {index !== steps.length - 1 && (
                                    <Button
                                        variant="contained"
                                        sx={{
                                            height: '1.875rem',
                                            color: '#ffffff',
                                            fontSize: '0.875rem',
                                            width: '4rem',
                                            marginTop: '1rem',
                                        }}
                                        onClick={() => next()}
                                    >
                                        Next
                                    </Button>
                                )}

                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </Paper>
        </Stack>

    );
}

export default PositionStepper;
