import React from 'react';
import {useUpdateAtom} from 'jotai/utils';
import {positionsAtom} from '../store/positionsStore';
import {IJobPosition} from '../models/IJobPosition';
import useJobPositionApi from '../api/useJobPositionApi';
import {ISearchFilter} from '../models/ISearchFilter';
import useUserApi from '../api/useUserApi';

const useJobPositions = () => {
    const setPositions = useUpdateAtom(positionsAtom);

    const {
        createPosition, deletePosition, getPosition, saveFilter,
    } = useJobPositionApi();
    const {getUserJobPositions} = useUserApi();

    const createJobPosition = async (id: number, jobPosition: IJobPosition) => {
        const position = await createPosition(id, jobPosition);
        await loadUserJobPositions();
        return position;
    };

    const deleteJobPosition = async (jobPositionId: number, comment: string) => {
        await deletePosition(jobPositionId, comment);
        await loadUserJobPositions();
    };

    const loadUserJobPositions = async () => {
        const jobPositions = await getUserJobPositions();
        setPositions(jobPositions);
    };

    const getJobPosition = async (id: number) => {
        const jobPosition = await getPosition(id);
        return jobPosition;
    };

    const savePositionFilter = async (positionId: number, filter: ISearchFilter) => {
        await saveFilter(positionId, filter);
    };

    return {
        createJobPosition,
        deleteJobPosition,
        loadUserJobPositions,
        getJobPosition,
        savePositionFilter,
    };
};

export default useJobPositions;
