import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Divider,
    Paper,
    Popover,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import {toast} from 'react-toastify';
import {styles} from '../../../components/theme';
import {ICompany} from "../../../models/ICompany";
import useCompaniesApi from "../../../api/useCompaniesApi";
import {IUserItem} from "../../../models/IUserItem";
import AddHrUserModal from "./AddHrUserModal";
import {IUser} from "../../../models/IUser";
import LockResetIcon from '@mui/icons-material/LockReset';
import {getAuth, sendPasswordResetEmail} from "firebase/auth";

const COMPANY_COLUMNS = [
    {name: 'Name'},
    {name: 'View'},
];

const USER_COLUMNS = [
    {name: 'Name'},
    {name: 'Email'},
    {name: 'Reset pw'},
];


function PasswordResetButton(props: { email: string }) {
    const [anchorConfirm, setAnchorConfirm] = useState(null);
    const resetConfirmationOpen = Boolean(anchorConfirm);

    const confirmReset = (event: any) => {
        setAnchorConfirm(event.currentTarget);
    }

    const resetConfirm = () => {
        setAnchorConfirm(null);
    };


    function doReset() {
        sendPasswordResetEmail(getAuth(), props.email, {url: 'https://app.jobling.ee/login'})
            .then(() => {
                toast.success('Password reset e-mail sent!')
            })
            .catch((error) => {
                if (error.code === 'auth/user-not-found') {
                    toast.error('User not found!')
                } else {
                    console.log(error.code)
                    const errorMessage = error.message;
                    toast.error(errorMessage)
                }
            });
        resetConfirm();
    }

    return <>
        <Popover
            id={"reset"}
            open={resetConfirmationOpen}
            anchorEl={anchorConfirm}
            onClose={resetConfirm}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box sx={{padding: "1rem"}}>
                <Typography>Send password reset e-mail to {props.email}?</Typography>
                <Button size={"small"} onClick={doReset}>Send</Button>
                <Button size={"small"} onClick={resetConfirm}> Cancel</Button>
            </Box>
        </Popover>
        <LockResetIcon fontSize={"small"} sx={{cursor: "pointer"}} onClick={confirmReset}/>
    </>
}

function AdminCompanies({
                            activePage,
                        }: {
    activePage: any,
}) {

    const [company, setCompany] = useState<ICompany>();
    const [companyUsers, setCompanyUsers] = useState<IUserItem[]>([]);

    const [companies, setCompanies] = useState<ICompany[]>([]);
    const {getCompanies, getUsersByCompanyId, createCompanyUser, updateCompany, addCompany} = useCompaniesApi();
    const [userModalOpen, setUserModalOpen] = useState<boolean>(false);
    const [nameEmptyError, setNameEmptyError] = useState<string>('');


    const loadData = async () => {
        getCompanies().then((data) => {
            setCompanies(data);
        }).catch((error) => {
            console.log(error);
            toast.error("Error loading companies");
        });
    };

    useEffect(() => {
        loadData();
        setCompany(undefined)
    }, [activePage]);

    function viewCompany(c: ICompany) {
        if (c.id) {
            setCompany(c)
            getUsersByCompanyId(c.id).then((data) => {
                setCompanyUsers(data);
            }).catch((error) => {
                console.log(error);
                toast.error("Error loading users for company");
            })
        }
    }

    function addUser(id: number) {
        setUserModalOpen(true)
    }

    function storeUser(user: IUser) {
        if (company?.id) {
            createCompanyUser(company.id, user).then(() => {
                toast.success("User added to company");
                setUserModalOpen(false)
                viewCompany(company)
            }).catch((e) => {
                if (e.name.includes('uq_user_email')) {
                    toast.error('An account already exists with this email!');
                } else {
                    toast.error('Failed to create user!');
                }
            });
        }
    }

    function storeCompany() {
        if (company) {
            if (company.name === undefined || company.name?.trim().length === 0) {
                setNameEmptyError('Company name is required');
                return;
            }
            if (company.id) {
                updateCompany(company).then((cc: ICompany) => {
                    toast.success("Company updated");
                    loadData();
                    setCompany(cc)
                }).catch((e) => {
                    console.log(e)
                    toast.error('Failed to update company!');
                });
            } else {
                addCompany(company).then((cc: ICompany) => {
                    toast.success("Company added");
                    setCompany(cc)
                    loadData();
                }).catch((e) => {
                    console.log(e)
                    toast.error('Failed to add company!');
                });
            }

        }
    }


    return (
        <Stack spacing={1}>
            <Paper elevation={0} sx={styles.paper}>

                {company ?
                    <>
                        <AddHrUserModal open={userModalOpen} handleClose={() => setUserModalOpen(false)}
                                        addUser={storeUser}/>
                        <Stack direction={"row"}>
                            <Typography typography="h4">{company.name}</Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1} sx={{marginTop: 1}}>
                            <TextField
                                autoFocus
                                error={nameEmptyError.length > 0}
                                helperText={nameEmptyError}
                                label="Company Name"
                                variant="standard"
                                sx={{
                                    '& .MuiInputBase-root': {
                                        height: 'inherit',
                                        marginTop: '0.5rem',
                                        marginBottom: '0.5rem',
                                        fontSize: '1.25rem'
                                    }, '& .MuiOutlinedInput-input': {
                                        padding: 0,
                                    }
                                }}
                                value={company.name}
                                onChange={(e) => {
                                    setCompany({...company, name: e.target.value})
                                }}/>

                        </Stack>
                        <Stack direction={"row"} spacing={1} sx={{marginTop: 3, marginBottom: 3}}>
                            {<Button variant="contained" onClick={() => storeCompany()}>Save</Button>}
                        </Stack>
                        {company.id &&
                            <>
                                <Divider>Users</Divider>

                                {companyUsers.length > 0 ?
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    {USER_COLUMNS.map((column, index) => (
                                                        <TableCell key={index}>{column.name}</TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {companyUsers.map((item, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{item.fullName}</TableCell>
                                                        <TableCell>{item.email}</TableCell>
                                                        <TableCell><PasswordResetButton email={item.email}/></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer> :
                                    <Typography sx={{marginTop: "1rem"}} textAlign={"center"}>No users
                                        found</Typography>}
                            </>}
                        <Stack direction={"row"} spacing={1} sx={{marginTop: 2}}>
                            <Button variant="contained" onClick={() => setCompany(undefined)}>Back</Button>
                            {company.id &&
                                <Button variant="contained" onClick={() => addUser(company.id!)}>Add User</Button>}
                        </Stack>
                    </>
                    :
                    <>
                        <Typography typography="h4">Companies</Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {COMPANY_COLUMNS.map((column, index) => (
                                            <TableCell key={index}>{column.name}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {companies.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell><ZoomInIcon fontSize={"small"} sx={{cursor: "pointer"}}
                                                                   onClick={() => viewCompany(item)}/></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Stack direction={"row"} spacing={1} sx={{marginTop: 5, marginBottom: 2}}>
                            <Button variant="contained" onClick={() => setCompany({name: ''} as ICompany)}>Add
                                Company</Button>
                        </Stack>
                    </>
                }
            </Paper>

        </Stack>
    );
}

export default AdminCompanies;
