import React from 'react';
import {toast} from 'react-toastify';
import useApi, {BASE_URL} from './useApi';
import {IUser} from '../models/IUser';
import Cookies from "js-cookie";

const USER_URL = `${BASE_URL}user`;
const USER_POSITIONS_URL = `${BASE_URL}user/positions`;

const useUserApi = () => {
    const {doFetch} = useApi();

    const getUserByPhone = (phone: string | null) => {
        const url = `${USER_URL}/by-phone/${phone}`;
        return doFetch(url);
    };

    const getUserByEmail = (email: string) => {
        const url = `${USER_URL}/by-email/${email}`;
        return doFetch(url);
    };

    const createUser = (user: IUser) => {
        let fbp = Cookies.get('_fbp')
        let fbc = Cookies.get('_fbc')

        let url = USER_URL + "?1=1"
        if (fbp) {
            url += "&fbp=" + fbp
        }
        if (fbc) {
            url += "&fbc=" + fbc
        }

        return doFetch(url, 'POST', {...user}).catch((e) => {
            if (e.name.includes('uq_user_phone')) {
                toast.error('An account already exists with this phone number!');
            } else if (e.name.includes('uq_user_email')) {
                toast.error('An account already exists with this email!');
            } else {
                toast.error('Failed to create user!');
            }
        });
    };

    const updateUser = (id: number, user: IUser) => {
        const url = `${USER_URL}/${id}`;
        return doFetch(url, 'PUT', {...user});
    };

    const remindLater = (id: number) => {
        const url = `${USER_URL}/${id}/remind-later`;
        return doFetch(url, 'POST', {});
    };

    const getUserJobPositions = () => {
        return doFetch(USER_POSITIONS_URL);
    };

    const getUsers = () => {
        const url = `${USER_URL}/for-admin`;
        return doFetch(url);
    };

    const getCompanyUsers = () => {
        const url = `${USER_URL}/for-company`;
        return doFetch(url);
    };

    return {
        getUsers,
        getCompanyUsers,
        getUserByPhone,
        createUser,
        updateUser,
        remindLater,
        getUserJobPositions,
        getUserByEmail,
    };
};

export default useUserApi;
