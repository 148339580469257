import {Box, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Typography,} from '@mui/material';
import React from 'react';
import {IDomainValue} from "../../models/IDomainValue";
import {useTranslation} from "react-i18next";
import CheckedIcon from "../../assets/CheckedIcon";
import UnCheckedIcon from "../../assets/UnCheckedIcon";
import MetrixTooltip from "../MetrixTooltip";


const MetrixSelectionList = ({
                                 metrixState,
                                 metrixList,
                                 onMetrixChange,
                                 groupLabel
                             }: { metrixState: any, metrixList: IDomainValue[], onMetrixChange: any, groupLabel: string }) => {

    const {t} = useTranslation();

    const drawGrid = (metrix: IDomainValue) => {
        return (
            <Grid item width='50%' key={metrix.code}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <FormControlLabel
                        sx={{marginLeft: 0, marginRight: 0}}
                        control={
                            <Checkbox
                                size={"small"}
                                onChange={(e) => onMetrixChange(e, metrix)}
                                checked={metrixState[metrix.code]}
                                name={metrix.code}
                                sx={{padding: '0.5rem'}}
                                checkedIcon={<CheckedIcon/>}
                                icon={<UnCheckedIcon/>}
                            />
                        }
                        label={t(`company.filter.metrix.${metrix.code}.title`)}
                    />
                    <MetrixTooltip metrixCode={metrix.code} disabled={false}/>
                </Box>
            </Grid>
        )
    };

    return (
        <Box sx={{marginTop: '1rem'}}>
            <FormControl
                sx={{margin: 0}}
            >
                <Typography sx={{fontWeight: 'bold'}}>{groupLabel}</Typography>
                <FormGroup>
                    <Grid container>
                        {metrixList.map((metrix) => drawGrid(metrix))}
                    </Grid>
                </FormGroup>
            </FormControl>
        </Box>
    );
}

export default MetrixSelectionList;
