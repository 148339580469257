import React, {useEffect, useState} from 'react';
import {Box, Checkbox, FormControlLabel, Paper, Stack, Typography,} from '@mui/material';
import {ISearchFilter} from '../../../../models/ISearchFilter';
import {DomainCode, IDomainValue} from '../../../../models/IDomainValue';
import {styles} from '../../../../components/theme';
import useDomainValues from '../../../../hooks/useDomainValues';
import ChartFilter from '../../../../components/ChartFilter';
import {UNIVERSITY_MAP} from "../../../../util/educationUtil";
import CheckedIcon from "../../../../assets/CheckedIcon";
import UnCheckedIcon from "../../../../assets/UnCheckedIcon";
import {universityCountAtom} from "../../../../store/domainValueStore";
import {useAtomValue} from "jotai";
import DomainValueSelect from "../../../../components/DomainValueSelect";

function CompanyFilterLeft({
                               searchFilter,
                               updateFilter,
                               chartUpdateKey
                           }: { searchFilter: ISearchFilter, updateFilter: any, chartUpdateKey: number }) {
    const [schools, setSchools] = useState<IDomainValue[]>([]);
    const [schoolsOnly, setSchoolsOnly] = useState<IDomainValue[]>([]);
    const [avgGrade, setAvgGrade] = useState<IDomainValue | null>(null);
    const {findDomainValuesByCode, findDomainValueByCode} = useDomainValues();

    const universityCount = useAtomValue(universityCountAtom);


    useEffect(() => {
        const onlySchools = findDomainValuesByCode(DomainCode.UNIVERSITY).sort((a, b) => b.count - a.count);
        setSchoolsOnly(onlySchools)

        const schoolsWithMajors: IDomainValue[] = [];
        onlySchools.map((s) => {
            if (UNIVERSITY_MAP[s.code]) {
                UNIVERSITY_MAP[s.code].forEach((item: string) => {
                    const subGroup = {...s, major: item};
                    schoolsWithMajors.push(subGroup)
                })
            } else {
                schoolsWithMajors.push(s);
            }
        })

        setSchools(schoolsWithMajors)

        setAvgGrade(findDomainValueByCode(DomainCode.METRIX, 'AVG_GRADE'));
    }, []);

    const setLanguages = (value: string[]) => {
        updateFilter({...searchFilter, languages: value}, true);
    };

    const setLocations = (value: string[]) => {
        updateFilter({...searchFilter, locations: value}, true);
    };

    const setWaysOfWorking = (value: string[]) => {
        updateFilter({...searchFilter, waysOfWorking: value}, true);
    };

    const setWorkField = (value: string[]) => {
        updateFilter({...searchFilter, fieldOfWorking: value}, true);
    };

    const handleUniversityChange = (uniCode: string, checked: boolean) => {
        let newUniversities = [...searchFilter.universities!]
        if (checked && UNIVERSITY_MAP[uniCode]) {
            newUniversities = newUniversities.filter((v) => !v.startsWith(uniCode))
            UNIVERSITY_MAP[uniCode].forEach((major: string) => {
                newUniversities.push(uniCode + ":" + major)
            })
        } else if (checked) {
            newUniversities.push(uniCode + ":" + 'undefined')
        } else {
            newUniversities = newUniversities.filter((v) => !v.startsWith(uniCode))
        }

        updateFilter({...searchFilter, universities: newUniversities}, true);
    };

    const handleMajorChange = (uniCode: string, major: string, checked: boolean) => {

        let newUniversities = [...searchFilter.universities!]

        if (checked) {
            newUniversities.push(uniCode + ":" + major)
        } else {
            newUniversities = newUniversities.filter((v) => !v.startsWith(uniCode + ":" + major))
        }

        updateFilter({...searchFilter, universities: newUniversities}, true);

    };

    return (
        <Stack sx={styles.leftStack} spacing={1}>
            <Paper elevation={0} sx={styles.paper}>
                <Typography typography="body1" sx={{marginBottom: '10px'}}>Employment type</Typography>
                <DomainValueSelect
                    domainCode={DomainCode.WORK_TYPE}
                    selectedValues={searchFilter?.waysOfWorking ? searchFilter?.waysOfWorking : []}
                    onSelect={setWaysOfWorking}
                />
            </Paper>

            <Paper elevation={0} sx={styles.paper}>
                <Typography typography="body1" sx={{marginBottom: '10px'}}>Field of work</Typography>
                <DomainValueSelect
                    domainCode={DomainCode.FIELD_OF_WORK}
                    selectedValues={searchFilter?.fieldOfWorking ? searchFilter?.fieldOfWorking : []}
                    onSelect={setWorkField}
                    excludedValues={[ 'OTHER']}

                />
            </Paper>

            <Paper elevation={0} sx={styles.paper}>
                <Typography typography="body1" sx={{marginBottom: '10px'}}>Languages</Typography>
                <DomainValueSelect
                    domainCode={DomainCode.LANGUAGE}
                    selectedValues={searchFilter?.languages ? searchFilter?.languages : []}
                    onSelect={setLanguages}
                />
            </Paper>

            <Paper elevation={0} sx={styles.paper}>
                <Typography typography="body1" sx={{marginBottom: '10px'}}>Locations</Typography>
                <DomainValueSelect
                    domainCode={DomainCode.LOCATION}
                    selectedValues={searchFilter?.locations ? searchFilter?.locations : []}
                    onSelect={setLocations}
                    excludedValues={['ANY', 'OTHER']}
                />
            </Paper>

            <Paper elevation={0} sx={styles.paper}>
                {avgGrade
                    ? (
                        <ChartFilter
                            searchFilter={searchFilter}
                            metrixDomain={avgGrade}
                            updateFilter={updateFilter}
                            chartUpdateKey={chartUpdateKey}
                        />
                    ) : ''}
            </Paper>

            {
                /*
                <Paper elevation={0} sx={styles.paper}>
                <Typography typography="body1" sx={{marginBottom: '10px'}}>Limit search to following
                    schools</Typography>
                <ThemeProvider theme={themeForMultiAutoSelect}>
                    <Autocomplete
                        multiple
                        size="small"
                        isOptionEqualToValue={(option, value) => option.code === value.code && option.major === value.major}
                        groupBy={(option) => option.value}
                        options={schools}
                        popupIcon={<ArrowDropDownIcon sx={{width: '1rem'}}/>}
                        value={schools.filter((s) => searchFilter.universities !== undefined && searchFilter.universities?.indexOf(s.code + ":" + s.major) !== -1)}

                        getOptionLabel={(option) => option.major !== undefined ? option.major + " (" + option.count + ")" : 'All majors'}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Type or select school"
                            />
                        )}
                        onChange={(event, value) => changeUniversity(value)}
                        renderTags={(value, getTagProps) => value.map((option, index) => (
                            <Chip
                                variant="outlined"
                                label={option.value}
                                size="small"
                                {...getTagProps({index})}
                            />
                        ))}
                    />
                </ThemeProvider>
            </Paper>
                 */
            }


            <Paper elevation={0} sx={styles.paper}>
                <Typography typography="body1" sx={{marginBottom: '10px'}}>Limit search to following
                    schools</Typography>

                {schoolsOnly.map((s, i) => {
                    const l = s.value + " (" + (universityCount.get(s.code) || 0) + ")"
                    return (
                        <React.Fragment key={"f_" + i}>
                            <FormControlLabel
                                key={"sc" + i}
                                label={l}
                                control={
                                    <Checkbox
                                        checkedIcon={<CheckedIcon/>}
                                        icon={<UnCheckedIcon/>}
                                        checked={searchFilter.universities !== undefined && searchFilter.universities?.filter(u => u.startsWith(s.code)).length > 0}
                                        onChange={(e) => handleUniversityChange(s.code, e.target.checked)}
                                    />
                                }
                            />

                            <Box sx={{display: 'flex', flexDirection: 'column', ml: 3}} key={"sb" + i}>
                                {
                                    UNIVERSITY_MAP[s.code] && (UNIVERSITY_MAP[s.code] as string[]).map((m, ii) => {
                                        const l = m + " (" + (universityCount.get(s.code + ":" + m) || 0) + ")"
                                        return (
                                            <FormControlLabel
                                                key={"um_" + i + "_" + ii}
                                                label={l}
                                                control={
                                                    <Checkbox
                                                        checkedIcon={<CheckedIcon/>}
                                                        icon={<UnCheckedIcon/>}
                                                        checked={searchFilter.universities !== undefined && searchFilter.universities?.indexOf(s.code + ":" + m) !== -1}
                                                        onChange={(e) => handleMajorChange(s.code, m, e.target.checked)}
                                                    />
                                                }
                                            />
                                        )

                                    })
                                }
                            </Box>
                        </React.Fragment>
                    )
                })}

            </Paper>
        </Stack>

    );
}

export default CompanyFilterLeft;
