import {Box, CircularProgress} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router';
import {useAtom, useAtomValue, useSetAtom} from 'jotai';
import {auth} from '../firebase-config';
import useJobPositions from '../hooks/useJobPositions';
import {IUser, UserType} from '../models/IUser';
import useProfile from '../hooks/useProfile';
import {profileSubmittedAtom, userAtom} from '../store/userStore';
import useUserApi from '../api/useUserApi';
import {IMetrixOverviews} from "../models/IMetrixOverviews";
import useUserProfileApi from "../api/useUserProfileApi";
import {overviewAtom} from "../store/metrixStore";
import {getAuth, UserInfo} from "firebase/auth";
import {findNotCompletedTests} from "../util/metrixUtil";
import {toast} from "react-toastify";

function AppAuthWrapper({children}: { children: JSX.Element }) {
    const [user, setUser] = useAtom(userAtom);
    const setMetrixOverviews = useSetAtom(overviewAtom);
    const profileSubmitted = useAtomValue(profileSubmittedAtom);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const {getUserByPhone, getUserByEmail} = useUserApi();
    const {getMetrixOverview} = useUserProfileApi();
    const {loadProfile} = useProfile();
    const {loadUserJobPositions} = useJobPositions();

    const loadInitial = async (user: IUser) => {
        if (location.pathname === '/game' || location.pathname === '/mess_game') {
            return
        }
        if (user.accountType === UserType.STUDENT) {
            await loadProfile();

            let fetchedMetrixOverview: IMetrixOverviews = await getMetrixOverview(user?.id!!);
            setMetrixOverviews(fetchedMetrixOverview)

            const notCompleted = findNotCompletedTests(fetchedMetrixOverview)

            if (notCompleted.length > 0 && location.pathname !== '/test') {
                navigate('test', {replace: true})

                return
            }

            if (notCompleted.length > 0 && location.pathname === '/test') {
                return
            }

            if (!profileSubmitted && location.pathname !== '/preferences') {
                navigate('/preferences')
                return
            }

            if (!profileSubmitted && location.pathname === '/preferences') {
                return
            }
        } else {
            await loadUserJobPositions();
        }
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
            if (authUser) {
                const providerInfo = authUser.providerData[0] as UserInfo

                let dbUser
                if ('phone' === providerInfo.providerId) {
                    dbUser = await getUserByPhone(authUser.phoneNumber);
                    dbUser.loginType = 'PHONE'
                    setUser(dbUser);
                } else {
                    getUserByEmail(authUser.email!).then(dbUser => {
                        dbUser.loginType = 'EMAIL'
                        setUser(dbUser)
                    }).catch(e => {
                        console.log("error", e)
                        if (e.message === 'OBJECT_NOT_FOUND') {
                            toast.error("Login failed, user with e-mail " + authUser.email + " not found!")
                        } else {
                            toast.error("Login failed!")
                        }
                        setIsLoading(false);
                        setUser(null);
                        getAuth().signOut().then(() => {
                            navigate('/login');
                            console.log("navigated back to login!")
                        });
                        console.log("navigated back to login2!")

                        return
                    });

                }

                if (location.pathname === '/login') {
                    navigate('/', {replace: true});
                    setIsLoading(false);
                    return;
                }
                navigate(location, {replace: true});
            } else {
                setUser(null);
                navigate(location, {replace: true});
            }
            setIsLoading(false);
        });
        return unsubscribe;
    }, []);

    useEffect(() => {
        if (user) {
            loadInitial(user!);
        }
    }, [user])

    if (isLoading) {
        return (
            <Box width="100%" height="100vh" display="flex" justifyContent="center" alignItems="center">
                <CircularProgress/>
            </Box>
        );
    }

    return children;
}

export default AppAuthWrapper;
