import {Components, createTheme, ThemeOptions} from '@mui/material/styles';
import React from 'react';
import {orange} from '@mui/material/colors';

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }

    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore
const themeOptions = {
    status: {
        danger: orange[500],
    },
    palette: {
        primary: {
            main: '#FFA049',
        },
        secondary: {
            main: '#809252',
        },
        success: {
            main: '#02B033',
        },
        background: {
            paper: '#fff',
            default: '#F7F7F7',
        },
    },
    typography: {
        fontSize: 16,
        htmlFontSize: 16,
        fontFamily: 'Poppins',
        h1: {
            color: '#809252',
            fontSize: '36px',
            fontWeight: 'bold',
            lineHeight: '42px',
        },
        h2: {
            height: '2.25rem',
            fontSize: '1.2rem',
            lineHeight: '1.75rem',
            fontWeight: '800',
            marginBottom: '0.9375rem',
        },
        h3: {
            fontSize: '1.25rem',
            // lineHeight: "1.465rem",
            marginTop: '0.5rem',
            marginBottom: '0.5rem',
        },
        h4: {
            color: '#000000',
            fontSize: '1.05rem',
            fontWeight: 500,
            // lineHeight: '1.19rem',
            //height: '1.19rem',
            marginTop: '0rem',
            marginBottom: '2rem',
        },
        h6: {
            color: '#000000',
            fontSize: '20px',
            lineHeight: '23px',
            fontWeight: 'bold',
        },
        body1: {
            color: '#000000',
            fontSize: '0.875rem',
            fontWeight: '400',
            //lineHeight: '17px',
        },
        body2: {
            color: '#303030',
            fontSize: '10px',
            lineHeight: '14px',
        },
        caption: {
            color: '#809252',
            fontSize: '0.75rem',
            lineHeight: '1.05em',
            fontWeight: '900',
            fontFamily: 'TT Hazelnuts',
            marginLeft: '1.69rem',
            marginTop: '0.94rem',
        },
    },
    components: {
        MuiContainer: {
            defaultProps: {
                maxWidth: false,
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    height: '1.65rem',
                    marginBottom: '1rem',
                    fontSize: '0.75rem'
                },
            },
        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#809252'
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    borderRadius: '0.5rem',
                    boxShadow: 'none',
                    '&:hover': {
                        backgroundColor: '#fff',
                        color: '#809252',
                    },
                },
                contained: {
                    color: 'white',
                    // backgroundColor: 'green'
                    '&:hover': {
                        backgroundColor: '#809252',
                        color: '#fff',
                        boxShadow: 'none',
                    },
                },
                outlined: {
                    color: '#809252',
                    //backgroundColor: 'green'

                }
            },
        },

        MuiInput: {
            styleOverrides: {
                root: {
                    // height: '1.65rem',
                    // marginBottom: '1rem',

                    fontSize: '0.75rem',
                    fontWeight: 400,

                    '&:before': {
                        border: 0,
                        boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.15)',
                    },
                    '&:after': {
                        border: 0,
                        boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.15)',
                    },
                    '&&:hover::before': {
                        border: 0,
                        boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.15)',
                    },
                    /*
                              '&:hover': {
                                  border: 0,
                                  boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.15)',
                              },
                               */
                },
                multiline: {
                    height: 'inherit',

                    '&:before': {
                        border: 0,
                        boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.15)',
                    },
                    '&:after': {
                        border: 0,
                        boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.15)',
                    },
                    '&&:hover::before': {
                        border: 0,
                        boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.15)',
                    },
                },

            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {},
                tag: {
                    fontSize: '0.7rem',
                    padding: '0.35rem',
                    borderColor: '#02B033',
                    borderRadius: '0.5rem',
                },
                inputRoot: {
                    paddingRight: "1rem !important"
                }
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    // height: '1.65rem',
                    // marginBottom: '1rem'
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    // height: '1.65rem',
                    // margin: 0,
                    // marginBottom: '1rem',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    // height: '1.65rem',
                    // marginBottom: '1rem',
                    // padding: 0
                    // paddingTop: 0
                },

                notchedOutline: {
                    borderRadius: 0,
                    border: 'none',
                    boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.15)',
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    color: '#A2DA93',
                    height: 1,
                    padding: '13px 0',
                    '& .MuiSlider-thumb': {
                        height: '1rem',
                        width: '1rem',
                        backgroundColor: '#fff',
                        border: '2px solid gray',
                        cursor: 'pointer',
                        //padding: '1rem'
                    },
                    '& .MuiSlider-rail': {
                        color: '#D3D3D3',
                        height: 3,
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    width: '1rem',
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    textAlign: 'right',
                    color: '#BFBFBF',
                    fontSize: '0.63rem',
                    lineHeight: '1.38rem',
                    fontWeight: '400',
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {},
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    // height: '1.125rem',
                    // padding: 0
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: 0,
                    height: '1.125rem',
                    fontSize: '0.8rem',
                    borderBottom: 'none',
                },
                head: {
                    fontSize: '0.6rem',
                    color: '#BEBEBE',
                    textTransform: 'uppercase',
                    fontWeight: 500,
                },
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    width: '1.5rem',
                    height: '1.5rem',
                    color: '#D3D3D3'
                },
                text: {
                    fill: '#FFFFFF',
                }
            }
        },
        MuiStepLabel: {
            styleOverrides: {
                root: {},
                label: {
                    color: '#D3D3D3',
                    fontSize: '0.875rem',
                    //fontWeight: '700',
                },
                '& .MuiStepLabel-label.Mui-active': {
                    color: '#000000',
                    fontWeight: '700',
                },
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: '#D3D3D3',
                    paddingLeft: 0
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                regular: {
                    height: '2.625rem !important',
                    minHeight: '2.625rem !important'
                }
            }
        }
    },
};
const theme = createTheme(themeOptions as ThemeOptions);
export const themeForMultiAutoSelect = createTheme({
    ...themeOptions,
    components: {
        ...themeOptions.components,
        MuiOutlinedInput: {
            ...themeOptions.components.MuiOutlinedInput,
            styleOverrides: {
                ...themeOptions.components.MuiOutlinedInput.styleOverrides,
                root: {
                    height: 'auto',
                    marginBottom: '1rem',
                },
            },
        },
        MuiAutocomplete: {
            ...themeOptions.components.MuiAutocomplete,
            styleOverrides: {
                ...themeOptions.components.MuiAutocomplete.styleOverrides,
                root: {
                    height: 'auto',
                    marginBottom: '1rem',
                },
            },
        },
    } as Components,
});

export const tomatoTheme = createTheme({
    ...themeOptions,
    palette: {
        primary: {
            main: '#FFA149',
            contrastText: '#FFF'
        },
    },
    typography: {
        fontSize: 16,
        htmlFontSize: 16,
        fontFamily: 'Poppins',
        h1: {
            fontSize: '2.5rem',
            fontWeight: 700,
        },
        h2: {
            fontSize: '1.5rem',
            fontWeight: 700,
        },
        h3: {
            fontSize: '1.25rem',
            fontWeight: 400,
        },
        h4: {
            color: '#000000',
            fontSize: '1.05rem',
            fontWeight: 500,
            // lineHeight: '1.19rem',
            //height: '1.19rem',
            marginTop: '0rem',
            marginBottom: '2rem',
        },
        h6: {
            color: '#000000',
            fontSize: '20px',
            lineHeight: '23px',
            fontWeight: 'bold',
        },
        body1: {
            fontSize: '1rem',
            fontWeight: 400
        },
        body2: {
            fontSize: '0.875rem',
            fontWeight: 400
        },
        caption: {
            fontSize: '0.75rem',
            fontWeight: 400
        },
        subtitle2: {
            fontSize: '0.625rem',
            fontWeight: 400
        }
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    maxWidth: '90rem !important'
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: '0.875rem',
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    color: '#A2DA93',
                    height: 1,
                    padding: '13px 0',
                    '& .MuiSlider-thumb': {
                        height: '1rem',
                        width: '1rem',
                        backgroundColor: '#fff',
                        border: '2px solid gray',
                        cursor: 'pointer',
                        //padding: '1rem'
                    },
                    '& .MuiSlider-rail': {
                        color: '#D3D3D3',
                        height: 3,
                    },
                },
            },
        },

        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    textTransform: 'none',
                    fontSize: '1rem',
                    borderRadius: '0.5rem',
                    borderColor: '#FFA149',
                    // color: '#FFA149'
                },
                text: {},
                outlined: {
                    color: '#FFA149'
                }
            }
        }
    } as Components,
});

export const styles = {
    paper: {
        background: '#FCFDFD',
        borderRadius: '0.5rem',
        padding: '1.25rem',
        width: '100%',
        //textAlign: 'center'
        // marginTop: '0.5rem'
    },
    paperCenter: {
        background: '#FCFDFD',
        borderRadius: '0.5rem',
        padding: '1.5rem',
        width: '100%',
        // textAlign: 'center',
        //height: '100%'
        // marginTop: '0.5rem'
    },

    h1: {
        fontSize: '1.75rem',
        fontWeight: 700,
    },
    h2: {
        fontSize: '1.25rem',
        fontWeight: 400,
    },
    body1: {
        fontSize: '1rem',
        fontWeight: 400
    },
    body2: {
        color: '#91939a',
        fontSize: '1.1rem',
        fontWeight: 300
    },
    body3: {
        fontSize: '0.875rem',
        fontWeight: 500
    },
    body4: {
        fontSize: '0.875rem',
        fontWeight: 400
    },
    body5: {
        fontSize: '0.75rem',
        fontWeight: 400
    },
    body6: {
        fontSize: '0.625rem',
        fontWeight: 400
    },

    body7: {
        fontSize: '0.625rem',
        fontWeight: 400,
        color: '#91939A'
    },
    matchText: {
        fontSize: '15px',
        margin: '2px',
    },
    matchCountMain: {
        fontWeight: 'bold',
        fontSize: '18px',
        background: '#F6F6F6',
        border: '1px solid #E3E3E3',
        boxSizing: 'border-box',
        borderRadius: '4px',
        padding: '7px',
        margin: '2px',
    },
    matchCount: {
        fontSize: '15px',
        background: '#F6F6F6',
        border: '1px solid #E3E3E3',
        boxSizing: 'border-box',
        borderRadius: '4px',
        padding: '3px',
        margin: '2px',
    },
    leftStack: {
        width: '21rem',
    },
    rightStack: {
        width: '18rem',
    },
    middleStack: {
        width: '40rem',
    },
    mismatch: {
        color: '#E78F8F',
        fontSize: '0.6rem',
        marginLeft: '0.5rem',
        marginTop: '-0.1rem',

    },
};

export default theme;
