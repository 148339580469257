import {Autocomplete, Box, Button, Dialog, Paper, Stack, TextField, Typography,} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useAtomValue} from 'jotai';
import {useNavigate} from 'react-router-dom';
import ActionButton, {ActionButtonType} from '../ActionButton';
import {styles} from '../theme';
import {IJobPosition} from '../../models/IJobPosition';
import {userAtom} from '../../store/userStore';
import useJobPositions from '../../hooks/useJobPositions';
import {positionsAtom} from '../../store/positionsStore';
import {toast} from "react-toastify";
import {DomainCode, IDomainValue} from "../../models/IDomainValue";
import {COMPETENCE, PERSONALITY, VALUE} from "../../util/metrixUtil";
import useDomainValues from "../../hooks/useDomainValues";
import {IMetrixRange, ISearchFilter} from "../../models/ISearchFilter";
import MetrixSelectionList from "./MetrixSelectionList";

function AddPositionModal({
                              open,
                              handleClose,
                              template = null
                          }: { open: boolean; handleClose: () => void; template?: IJobPosition | null }) {
    const [name, setName] = useState('');
    const [templatePosition, setTemplatePosition] = useState<IJobPosition | null>(null);
    const {createJobPosition, savePositionFilter} = useJobPositions();
    const user = useAtomValue(userAtom);
    const positions = useAtomValue(positionsAtom);
    const navigate = useNavigate();

    const {findDomainValuesByCode} = useDomainValues();
    const [personalityList, setPersonalityList] = useState<IDomainValue[]>([]);
    const [competenceList, setCompetenceList] = useState<IDomainValue[]>([]);
    const [valuesList, setValuesList] = useState<IDomainValue[]>([]);
    const [metrixState, setMetrixState] = useState<any>({});

    const init = async () => {
        const dv = findDomainValuesByCode(DomainCode.METRIX);
        setMetrixState(new Array(dv.length).fill(false))

        const initialMetrixState: any = {};
        dv.forEach(d => {
            initialMetrixState[d.code] = false
        })
        setMetrixState(initialMetrixState);

        const personalities = dv.filter((d) => PERSONALITY.includes(d.code)).sort((d) => d.orderNr) as IDomainValue[];
        setPersonalityList((prev) => personalities);

        const competences = dv.filter((d) => COMPETENCE.includes(d.code)).sort((d) => d.orderNr) as IDomainValue[];
        setCompetenceList((prev) => competences);

        const values = dv.filter((d) => VALUE.includes(d.code)).sort((d) => d.orderNr) as IDomainValue[];
        setValuesList((prev) => values);
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (template) {
            setTemplatePosition(template);
        }
    }, [template]);

    useEffect(() => {
    }, [metrixState]);

    const createNewPosition = async (name: string) => {
        if (!name || name.trim().length < 3) {
            toast.error("Please use name that is at least 3 characters long!");
            return;
        }
        const position = {
            id: 0,
            name,
            userId: user?.id,
            filter: templatePosition ? templatePosition.filter : '{}',
        } as IJobPosition;

        const addedPosition = await createJobPosition(user?.id || 0, position);

        if (templatePosition === null) {
            let metrixRanges: IMetrixRange[] = [];
            Object.keys(metrixState).forEach((key, index) => {
                if (metrixState[key]) {
                    metrixRanges.push({metrix: key, range: [0, 100]} as IMetrixRange)
                }
            });

            const newFilter = {
                metrixRanges: metrixRanges,
            } as ISearchFilter;

            await savePositionFilter(parseInt(addedPosition.id), newFilter);
        }


        navigate(`/positions/${addedPosition.id}/filter`);
        handleClose();
    };

    const onTemplateChange = (value: IJobPosition | null) => {

        const newMetrixState = metrixState;
        if (value) {
            const loadedFilter = JSON.parse(value?.filter || "") as ISearchFilter;
            loadedFilter.metrixRanges?.forEach(r => {
                newMetrixState[r.metrix] = true;
            })
        } else {
            Object.keys(metrixState).forEach((key) => {
                newMetrixState[key] = false;
            });
        }

        setMetrixState(newMetrixState);
        setTemplatePosition(value);
    };

    const onMetrixChange = (event: React.ChangeEvent<HTMLInputElement>, metrix: IDomainValue) => {
        const newMetrixState = {
            ...metrixState,
            [metrix.code]: event.target.checked
        };
        setMetrixState(newMetrixState);
    }


    return (
        <Dialog open={open} onClose={handleClose}>
            <Stack sx={{width: '100%'}}>
                <Paper elevation={0} sx={styles.paper}>
                    <Typography typography="h3">Add new position</Typography>
                    <TextField
                        sx={{width: '100%'}}
                        variant="standard"
                        placeholder="Job position"
                        autoFocus
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <Autocomplete
                        disablePortal
                        options={positions}
                        value={templatePosition}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(e, value) => onTemplateChange(value)}
                        renderInput={(params) => <TextField variant="standard" {...params}
                                                            placeholder="Choose from template"/>}
                    />

                    <>
                        <Typography>There are over 40 different filters to choose candidates based on their Personality,
                            Competencies, and Values. We recommend selecting 3-4 filters of each that are of critical
                            importance for your team or to the position you are looking for.</Typography>
                        <MetrixSelectionList metrixState={metrixState} metrixList={personalityList}
                                             onMetrixChange={onMetrixChange} groupLabel="Personality"/>
                        <MetrixSelectionList metrixState={metrixState} metrixList={competenceList}
                                             onMetrixChange={onMetrixChange} groupLabel="Competencies"/>
                        <MetrixSelectionList metrixState={metrixState} metrixList={valuesList}
                                             onMetrixChange={onMetrixChange} groupLabel="Values"/>
                    </>

                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Box style={{flexGrow: 1}}/>
                        <Button
                            variant="text"
                            sx={{fontSize: '0.875rem', color: '#809252'}}
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </Button>
                        <ActionButton
                            label="Create"
                            type={ActionButtonType.SUBMIT}
                            onClick={() => createNewPosition(name)}
                        />
                    </Box>
                </Paper>
            </Stack>
        </Dialog>
    );
}

export default AddPositionModal;
