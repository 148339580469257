import React, {useEffect, useState} from 'react';
import {Box, Checkbox, Slider, Typography,} from '@mui/material';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {IChartData, IDataPoint} from '../models/ChartData';
import useChartApi from '../api/useChartApi';
import {IMetrixRange, ISearchFilter} from '../models/ISearchFilter';
import {IDomainValue} from '../models/IDomainValue';
import HatsBarChart from './HatsBarChart';
import MetrixTooltip from './MetrixTooltip';
import CheckedIcon from "../assets/CheckedIcon";
import UnCheckedIcon from "../assets/UnCheckedIcon";
import {useUpdateAtom} from "jotai/utils";
import {lastMetrixAtom} from "../store/metrixStore";

function ChartFilter({
                         searchFilter,
                         metrixDomain,
                         updateFilter,
                         chartUpdateKey
}: { searchFilter: ISearchFilter, metrixDomain: IDomainValue, updateFilter: any, chartUpdateKey: number }) {
    const [data, setData] = useState<IDataPoint[]>([]);
    const [value, setValue] = useState<number | number[]>([0, 100]);
    const [color, setColor] = useState<string>('#FFA049');
    const [smallCount, setSmallCount] = useState<number>(0);
    const setLastMetix = useUpdateAtom(lastMetrixAtom);

    const {id} = useParams();
    const {getGradeData} = useChartApi();
    const {t} = useTranslation();

    useEffect(() => {
        if(metrixDomain.range) {
            setValue(metrixDomain.range);
        } else {
            setValue([0, 100]);

        }
    }, [metrixDomain.range]);

    useEffect(() => {
        if (value) {
            const valueArray = value as number[];
            if ((valueArray[0] > 0 || valueArray[1] < 100)) {
                setColor((prev) => '#FFA049');
            } else {
                setColor((prev) => '#A2DA93');
            }
            if(!metrixDomain.selected && metrixDomain.code !== 'AVG_GRADE') {
                setColor(prev =>  '#D3D3D3');
            }
            if (data) {
                let sum = 0;
                for (let i = valueArray[0] + 5; i <= valueArray[1] - 5; i += 10) {
                    const dp = data.find((dp) => dp.key === i);
                    sum += dp ? dp.value : 0;
                }
                setSmallCount((prev) => sum);
            }
        }
    }, [value, data, metrixDomain]);

    useEffect(() => {
        loaddata();
    }, [chartUpdateKey]);

    const loaddata = async () => {
        if (id) {
            const s = await getGradeData(searchFilter, metrixDomain.code, +id) as IChartData;
            if (s) {
                setData(s.data);
            }
        }
    };

    const changeValue = (newValue: number | number[]) => {
        if (value.toString() !== newValue.toString()) {
            setValue(newValue);
            const newFilter = {...searchFilter};

            if (!newFilter.metrixRanges) {
                newFilter.metrixRanges = [];
            }

            const newRangeObject = {metrix: metrixDomain.code, range: newValue as number[]} as IMetrixRange;
            const index = newFilter.metrixRanges.findIndex((r) => r.metrix == metrixDomain.code);
            if (index === -1) {
                newFilter.metrixRanges.push(newRangeObject);
            } else {
                newFilter.metrixRanges[index] = newRangeObject;
            }

            updateFilter(newFilter, false);
        }
    };

    const handleMetrixCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newFilter = {...searchFilter};

        if (!newFilter.metrixRanges) {
            newFilter.metrixRanges = [];
        }
        const index = newFilter.metrixRanges.findIndex((r) => r.metrix == metrixDomain.code);

        if(event.target.checked) {
            const newRangeObject = {metrix: metrixDomain.code, range: [0, 100]} as IMetrixRange;

            if (index === -1) {
                newFilter.metrixRanges.push(newRangeObject);
            } else {
                newFilter.metrixRanges[index] = newRangeObject;
            }

            setLastMetix(metrixDomain.code);
            updateFilter(newFilter, true, true);
        } else {
            setValue([0, 100])
            newFilter.metrixRanges.splice(index, 1)
            updateFilter(newFilter, true);
        }
    }

    return (
        <>
            <Box sx={{
                width: '100%', display: 'flex', alignItems: 'center', marginTop: '-0.5rem',
            }}
            >
                {metrixDomain.code !== 'AVG_GRADE' ? (
                    <>
                        <Checkbox size={"small"} checked={metrixDomain.selected} onChange={handleMetrixCheckbox}
                                  sx={{padding: '0.5rem'}} checkedIcon={<CheckedIcon />} icon={<UnCheckedIcon />}/>
                        <Typography
                            style={{fontWeight: 500}}>{t(`company.filter.metrix.${metrixDomain.code}.title`)}</Typography>
                        <MetrixTooltip metrixCode={metrixDomain.code} />
                    </>

                ) : (
                    <Typography
                        style={{fontWeight: 500}}>{t(`company.filter.metrix.${metrixDomain.code}.title`)}</Typography>
                )}
                <Box sx={{flexGrow: 1}}/>
                {
                    /*
                    <PersonIcon sx={{fontSize: "0.8rem"}} />
                     */
                }

                <Typography style={{fontSize: '0.6rem', color: 'rgba(0,0,0, 0.5)'}}>{smallCount}</Typography>
            </Box>
            <HatsBarChart data={data} color={color}/>
            <Slider
                style={{padding: 0, color}}
                //disableSwap
                valueLabelDisplay="auto"
                step={10}
                value={value}
                disabled={!metrixDomain.selected && metrixDomain.code !== 'AVG_GRADE'}
                onChange={(event, value) => changeValue(value)}
            />
            <Box sx={{display: 'flex', marginLeft: '-0.5rem', marginRight: '-0.5rem', marginTop: '0.25rem'}}>
                <Typography
                    sx={{fontSize: '0.7rem'}}
                >
                    {t(`company.filter.metrix.${metrixDomain.code}.leftLabel`)}
                </Typography>
                <Box sx={{flexGrow: 1}}/>
                <Typography
                    sx={{fontSize: '0.7rem'}}
                >
                    {t(`company.filter.metrix.${metrixDomain.code}.rightLabel`)}
                </Typography>
            </Box>
        </>
    );
}

export default ChartFilter;
