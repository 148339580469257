import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    Paper,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useAtomValue} from 'jotai';
import {useNavigate} from 'react-router-dom';
import ActionButton, {ActionButtonType} from '../ActionButton';
import {styles} from '../theme';
import {IJobPosition} from '../../models/IJobPosition';
import {userAtom} from '../../store/userStore';
import useJobPositions from '../../hooks/useJobPositions';
import {toast} from "react-toastify";
import useDomainValues from "../../hooks/useDomainValues";
import {DomainCode, IDomainValue} from "../../models/IDomainValue";
import {COMPETENCE, PERSONALITY, VALUE} from "../../util/metrixUtil";
import {useTranslation} from "react-i18next";
import CheckedIcon from "../../assets/CheckedIcon";
import {IMetrixRange, ISearchFilter} from "../../models/ISearchFilter";
import UnCheckedIcon from "../../assets/UnCheckedIcon";
import MetrixTooltip from "../MetrixTooltip";

const style = {
    // position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    width: '37.75rem',
};

function AddPositionForm() {
    const [name, setName] = useState('');
    const {createJobPosition, savePositionFilter} = useJobPositions();
    const user = useAtomValue(userAtom);
    const navigate = useNavigate();

    const {findDomainValuesByCode} = useDomainValues();
    const [personalityList, setPersonalityList] = useState<IDomainValue[]>([]);
    const [competenceList, setCompetenceList] = useState<IDomainValue[]>([]);
    const [valuesList, setValuesList] = useState<IDomainValue[]>([]);
    const [metrixState, setMetrixState] = useState<any>({});

    const {t} = useTranslation();

    const init = async () => {
        const dv = findDomainValuesByCode(DomainCode.METRIX);
        setMetrixState(new Array(dv.length).fill(false))

        const initialMetrixState: any = {};
        dv.forEach(d => {
            initialMetrixState[d.code] = false
        })
        setMetrixState(initialMetrixState);

        const personalities = dv.filter((d) => PERSONALITY.includes(d.code)).sort((d) => d.orderNr) as IDomainValue[];
        setPersonalityList((prev) => personalities);

        const competences = dv.filter((d) => COMPETENCE.includes(d.code)).sort((d) => d.orderNr) as IDomainValue[];
        setCompetenceList((prev) => competences);

        const values = dv.filter((d) => VALUE.includes(d.code)).sort((d) => d.orderNr) as IDomainValue[];
        setValuesList((prev) => values);
    };

    useEffect(() => {
        init();
    }, []);

    const createNewPosition = async (name: string) => {
        if (!name || name.trim().length < 3) {
            toast.error("Please use name that is at least 3 characters long!");
            return;
        }
        const position = {
            id: 0,
            name,
            userId: user?.id,
            filter: '{}',
        } as IJobPosition;

        const addedPosition = await createJobPosition(user?.id || 0, position);


        let metrixRanges: IMetrixRange[] = [];
        Object.keys(metrixState).forEach((key) => {
            if (metrixState[key]) {
                metrixRanges.push({metrix: key, range: [0, 100]} as IMetrixRange)
            }
        });

        const newFilter = {
            metrixRanges: metrixRanges,
        } as ISearchFilter;

        await savePositionFilter(parseInt(addedPosition.id), newFilter);

        navigate(`/positions/${addedPosition.id}/filter?intro=true`);

    };

    const onMetrixChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newMetrixState = metrixState;
        newMetrixState[event.target.name] = event.target.checked;

        setMetrixState(newMetrixState);
    }

    const drawGrid = (metrix: IDomainValue) => {
        return (
            <Grid item width='50%' key={metrix.code}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <FormControlLabel
                        sx={{marginLeft: 0, marginRight: 0}}
                        control={
                            <Checkbox size={"small"} onChange={onMetrixChange} name={metrix.code}
                                      sx={{padding: '0.5rem'}} checkedIcon={<CheckedIcon/>} icon={<UnCheckedIcon/>}/>
                        }
                        label={t(`company.filter.metrix.${metrix.code}.title`)}
                    />
                    <MetrixTooltip metrixCode={metrix.code} disabled={false}/>
                </Box>
            </Grid>
        )
    };

    return (
        <Stack sx={style}>
            <Paper elevation={0} sx={styles.paper}>
                <Typography typography="h4">Add first position</Typography>
                <TextField
                    sx={{width: '100%', fontSize: '0.8125rem'}}
                    variant="standard"
                    placeholder="Position"
                    autoFocus
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                <Typography>There are over 40 different filters to choose candidates based on their Personality,
                    Competencies, and Values. We recommend selecting 3-4 filters of each that are of critical importance
                    for your team or to the position you are looking for.</Typography>

                <Box sx={{marginTop: '1rem'}}>
                    <FormControl
                        sx={{margin: 0}}
                    >
                        <FormLabel component="legend" sx={{fontWeight: 'bold'}}>Personality</FormLabel>
                        <FormGroup>
                            <Grid container>
                                {personalityList.map((metrix) => drawGrid(metrix))}
                            </Grid>
                        </FormGroup>
                    </FormControl>
                </Box>

                <Box sx={{marginTop: '1rem'}}>
                    <FormControl
                        sx={{margin: 0}}
                    >
                        <FormLabel component="legend" sx={{fontWeight: 'bold'}}>Competencies</FormLabel>
                        <FormGroup>
                            <Grid container>
                                {competenceList.map((metrix) => drawGrid(metrix))}
                            </Grid>
                        </FormGroup>
                    </FormControl>
                </Box>

                <Box sx={{marginTop: '1rem'}}>
                    <FormControl
                        sx={{margin: 0}}
                    >
                        <FormLabel component="legend" sx={{fontWeight: 'bold'}}>Values</FormLabel>
                        <FormGroup>
                            <Grid container>
                                {valuesList.map((metrix) => drawGrid(metrix))}
                            </Grid>
                        </FormGroup>
                    </FormControl>
                </Box>

                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Box style={{flexGrow: 1}}/>
                    <ActionButton
                        label="Add"
                        type={ActionButtonType.SUBMIT}
                        onClick={() => createNewPosition(name)}
                    />
                </Box>
            </Paper>
        </Stack>
    );
}

export default AddPositionForm;
