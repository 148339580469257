import React, {useEffect, useRef, useState} from 'react';
import {Box, Grid, Paper, Stack, Tab, Tabs, Typography,} from '@mui/material';
import {ISearchFilter} from '../../../../models/ISearchFilter';
import ChartFilter from '../../../../components/ChartFilter';
import {styles} from '../../../../components/theme';
import useDomainValues from '../../../../hooks/useDomainValues';
import {DomainCode, IDomainValue} from '../../../../models/IDomainValue';
import {COMPETENCE, PERSONALITY, VALUE} from "../../../../util/metrixUtil";
import {lastMetrixAtom} from "../../../../store/metrixStore";
import {useAtom} from "jotai";

function CompanyFilterMiddle({
                                 searchFilter,
                                 updateFilter,
                                 chartUpdateKey,
                                 matchCount,
                             }: { searchFilter: ISearchFilter, updateFilter: any, chartUpdateKey: number, matchCount: number[] }) {
    const {findDomainValuesByCode} = useDomainValues();
    const [personalityList, setPersonalityList] = useState<IDomainValue[]>([]);
    const [competenceList, setCompetenceList] = useState<IDomainValue[]>([]);
    const [valuesList, setValuesList] = useState<IDomainValue[]>([]);
    const [tabValue, setTabValue] = useState(0);
    const [lastMetrix, setLastMetrix] = useAtom(lastMetrixAtom);
    const refs = useRef<any>({});
    const [loaded, setLoaded] = useState(false)

    const init = async () => {
        if (searchFilter) {
            setLoaded(false)
            const dv = findDomainValuesByCode(DomainCode.METRIX);

            const personalities: IDomainValue[] = [];
            const competences: IDomainValue[] = [];
            const values: IDomainValue[] = [];

            const selectedMetrixes: string[] = searchFilter.metrixRanges?.map(m => m.metrix) || [];

            dv.forEach(d => {
                const value = searchFilter.metrixRanges?.find((f) => f.metrix === d.code)

                d.selected = selectedMetrixes.includes(d.code);
                if (value) {
                    d.range = value.range;
                } else {
                    d.range = [0, 100];
                }
                if (PERSONALITY.includes(d.code)) {
                    personalities.push(d);
                } else if (COMPETENCE.includes(d.code)) {
                    competences.push(d);
                } else if (VALUE.includes(d.code)) {
                    values.push(d);
                }
            })

            const p = personalities.sort((m) => m.selected ? -1 : 1) as IDomainValue[];
            const c = competences.sort((m) => m.selected ? -1 : 1) as IDomainValue[];
            const v = values.sort((m) => m.selected ? -1 : 1) as IDomainValue[];

            setPersonalityList((prev) => p);

            setCompetenceList((prev) => c);

            setValuesList((prev) => v);

            setLoaded(true)
        }

    };

    useEffect(() => {
        init();
    }, [searchFilter]);

    useEffect(() => {
        if (lastMetrix !== null && refs.current[lastMetrix]) {
            setTimeout(() => {
                refs.current[lastMetrix].scrollIntoView({behavior: "smooth", block: "center"});
                setLastMetrix(null);
            }, 100)
        }
    }, [lastMetrix]);

    return (
        <Stack sx={styles.middleStack}>

            <Tabs value={tabValue} onChange={(event, value) => setTabValue(value)}>
                <Tab label="Personality"/>
                <Tab label="Competencies"/>
                <Tab label="Values"/>
            </Tabs>

            <Grid container spacing={1}>
                {
                    matchCount[0] == 0 && matchCount[1] == 0 && matchCount[2] == 0 ? (
                        <Grid item lg={12}>
                            <Paper elevation={0} sx={{...styles.paper}}>
                                <Typography typography={"h4"}>No results with given filters!</Typography>
                            </Paper>
                        </Grid>
                    ) : (
                        <>
                            {tabValue === 0 && loaded && (
                                personalityList.map((metrix, index) => (
                                    <Grid item key={index} lg={6} ref={(el) => refs.current[metrix.code] = el}>

                                        <Paper elevation={0} sx={{...styles.paper, padding: '1rem'}}>
                                            {personalityList.length > 0
                                                ? (
                                                    <ChartFilter
                                                        searchFilter={searchFilter} metrixDomain={metrix}
                                                        updateFilter={updateFilter} chartUpdateKey={chartUpdateKey}
                                                    />
                                                ) : ''}
                                        </Paper>


                                    </Grid>
                                ))
                            )}

                            {tabValue === 1 && (
                                competenceList.map((metrix, index) => (
                                    <Grid item key={index} lg={6} ref={(el) => refs.current[metrix.code] = el}>
                                        <Paper elevation={0} sx={{...styles.paper, padding: '1rem'}}>
                                            {competenceList[0]
                                                ? (
                                                    <ChartFilter
                                                        searchFilter={searchFilter} metrixDomain={metrix}
                                                        updateFilter={updateFilter} chartUpdateKey={chartUpdateKey}
                                                    />
                                                ) : ''}
                                        </Paper>
                                    </Grid>
                                ))
                            )}
                            {tabValue === 2 && (
                                valuesList.map((metrix, index) => (
                                    <Grid item key={index} lg={6} ref={(el) => refs.current[metrix.code] = el}>
                                        <Paper elevation={0} sx={{...styles.paper, padding: '1rem'}}>
                                            {valuesList[0]
                                                ? (
                                                    <ChartFilter
                                                        searchFilter={searchFilter} metrixDomain={metrix}
                                                        updateFilter={updateFilter} chartUpdateKey={chartUpdateKey}
                                                    />
                                                ) : ''}
                                        </Paper>
                                    </Grid>
                                ))
                            )}
                        </>
                    )
                }

            </Grid>

            <Box width="100%" height="4rem"/>

        </Stack>

    );
}

export default CompanyFilterMiddle;
