import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Stack} from '@mui/material';
import {useParams} from 'react-router-dom';
import CompanyOfferLeft from './CompanyOfferLeft';
import CompanyOfferMiddle from './CompanyOfferMiddle';
import Layout from '../../../Layout';
import CompanyPositionHeader from '../../../../components/company/CompanyPositionHeader';
import {IFilteredStudent} from '../../../../models/IFilteredStudent';
import useJobPositionApi from '../../../../api/useJobPositionApi';
import PositionStepper from "../PositionStepper";

function CompanyOffer() {
    const {getConfirmedStudents} = useJobPositionApi();
    const {id} = useParams();

    const [students, setStudents] = useState<IFilteredStudent[]>([]);

    useEffect(() => {
        loadStudents();
    }, [id]);

    const loadStudents = async () => {
        if (id) {
            const loadedStudents = await getConfirmedStudents(parseInt(id)) as IFilteredStudent[];
            if (loadedStudents) {
                setStudents(loadedStudents);
            }
        }
    };


    return (
        <Layout>
            <Stack spacing={1}>
                <CompanyPositionHeader/>

                <Stack direction="row" spacing={1}>
                    <CompanyOfferLeft students={students}/>
                    <CompanyOfferMiddle students={students}/>

                    <PositionStepper activeStep={2}>
                        <></>
                    </PositionStepper>
                </Stack>

            </Stack>
        </Layout>
    );
}

export default CompanyOffer;
