import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Stack} from '@mui/material';
import {toast} from 'react-toastify';
import {useNavigate, useParams} from 'react-router-dom';
import CompanyProfileSelectLeft from './CompanyProfileSelectLeft';
import Layout from '../../../Layout';
import CompanyPositionHeader from '../../../../components/company/CompanyPositionHeader';
import {FilterStatus, IUserProfile} from '../../../../models/IUserProfile';
import {IFilterResult} from '../../../../models/IFilterResult';
import useJobPositionApi from '../../../../api/useJobPositionApi';
import useFilterApi from '../../../../api/useFilterApi';
import {IFilteredStudent} from '../../../../models/IFilteredStudent';
import useUserProfileApi from '../../../../api/useUserProfileApi';
import CompanyProfileSelectMiddle from './CompanyProfileSelectMiddle';
import CompanyProfileSelectRight from './CompanyProfileSelectRight';
import PositionStepper from "../PositionStepper";

function CompanyProfileSelect() {
    const {id} = useParams();
    const {searchResults} = useFilterApi();
    const {getUserProfile} = useUserProfileApi();
    const navigate = useNavigate();

    const {changeStudentFilterStatus} = useJobPositionApi();
    const [students, setStudents] = useState<IFilteredStudent[]>([]);
    const [activeProfile, setActiveProfile] = useState<IUserProfile | null>(null);

    useEffect(() => {
        if (id) {
            loadStudents();
        }
    }, [id]);

    const activateProfile = async (student: IFilteredStudent) => {
        const profile = await getUserProfile(student.id) as IUserProfile;
        profile.filterStatus = student.filterStatus;
        setActiveProfile((prev) => profile);
    };

    const loadStudents = async () => {
        if (id) {
            const dbProfiles = await searchResults(parseInt(id)) as IFilterResult;
            if (dbProfiles) {
                setStudents((prev) => dbProfiles.students);
                if (dbProfiles.students.length > 0) {
                    await activateProfile(dbProfiles.students[0]);
                }
            }
            if (dbProfiles?.limitExceededWarning === true) {
                toast.warn('More than 50 profiles found. Displaying first 50.');
            }
        }
    };

    const activateNextWithSameStatus = async (index: number, p: IFilteredStudent[], filterStatus: FilterStatus | null | undefined) => {
        if (filterStatus) {
            for (let i = index + 1; i < p.length; i++) {
                if (p[i].filterStatus === filterStatus) {
                    await activateProfile(p[i]);
                    return;
                }
            }
            for (let i = 0; i < index; i++) {
                if (p[i].filterStatus === filterStatus) {
                    await activateProfile(p[i]);
                    return;
                }
            }
            for (let i = 0; i < p.length; i++) {
                if (p[i].filterStatus === FilterStatus.PENDING) {
                    await activateProfile(p[i]);
                    return;
                }
            }
            await activateProfile(p[index]);
        }
    };

    const changeStatus = (profile: IUserProfile | null, status: FilterStatus) => {
        const profileId = profile?.id;
        if (id && profile && profileId !== undefined) {
            changeStudentFilterStatus(parseInt(id), profileId, status).then(async () => {
                const index = students.findIndex((p) => p.id === profileId);
                const newStudents = Object.assign([], students) as IFilteredStudent[];
                newStudents[index].filterStatus = status;
                setStudents((prev) => newStudents);
                await activateNextWithSameStatus(index, students, profile.filterStatus);
            }).catch((e) => {
                console.log(e);
                toast.error('Failed change profile status');
            });
        }
    };

    return (
        <Layout>
            <Stack spacing={1}>
                <CompanyPositionHeader/>
                <Stack direction="row" spacing={1}>
                    <CompanyProfileSelectLeft
                        students={students}
                        activeProfile={activeProfile}
                        setActiveProfile={activateProfile}
                    />
                    <CompanyProfileSelectMiddle activeProfile={activeProfile} changeStatus={changeStatus}/>


                    <PositionStepper activeStep={1}>
                        <CompanyProfileSelectRight
                            students={students}
                            activeProfile={activeProfile}
                            setActiveProfile={activateProfile}
                        />
                    </PositionStepper>
                </Stack>
            </Stack>
        </Layout>
    );
}

export default CompanyProfileSelect;
